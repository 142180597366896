import React from 'react';
import Box from '~components/Box';
import bemClassNames from '@enrise/bemclassnames';
import Dot from '~components/Dot';
import Line from '~components/Line';
import { useGameState } from '~state/game';

const cn = bemClassNames('PlayingField');

type PlayingFieldRowProps = {
  lastRow?: boolean;
  rowNr: number;
};
const PlayingFieldRow = ({ lastRow = false, rowNr }: PlayingFieldRowProps) => {
  const [{ width: nrOfColumns }] = useGameState();

  const oddColumns = [];
  const evenColumns = [];

  for (let columnNr = 0; columnNr <= nrOfColumns; columnNr++) {
    const lastColumn = columnNr === nrOfColumns;
    oddColumns.push(<Dot key={`dot-${columnNr}`} />);
    if (!lastRow) {
      evenColumns.push(
        <Line
          direction="vertical"
          x={columnNr}
          y={rowNr}
          key={`row-vertical-${columnNr}`}
        />
      );
    }
    if (!lastColumn) {
      oddColumns.push(
        <Line
          direction="horizontal"
          x={columnNr}
          y={rowNr}
          key={`row-horizontal-${columnNr}`}
        />
      );
    }
    if (!lastColumn && !lastRow) {
      evenColumns.push(<Box x={columnNr} y={rowNr} key={`box-${columnNr}`} />);
    }
  }

  return (
    <>
      <div className={cn('row')}>{oddColumns}</div>
      <div className={cn('row')}>{evenColumns}</div>
    </>
  );
};

export default PlayingFieldRow;
