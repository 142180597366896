import React, { useState, useEffect } from 'react';
import bemClassNames from '@enrise/bemclassnames';
import './Line.scss';
import { useMovesState } from '~state/moves';
import { useGameState } from '~state/game';

const cn = bemClassNames('Line');

type LineProps = {
  direction: MoveType['direction'];
  x: number;
  y: number;
};
const Line = ({ direction, x, y }: LineProps) => {
  const [active, setActive] = useState(false);
  const [activePlayer, setActivePlayer] = useState<number>();
  const [moves, setMoves] = useMovesState();
  const [{ players, currentPlayerId }] = useGameState();

  const handleMove = () => {
    setMoves((prevMoves) => [
      ...prevMoves,
      { direction, x, y, player: currentPlayerId },
    ]);
  };

  useEffect(() => {
    if (active || !moves.length) {
      return;
    }
    const lastMove = moves[moves.length - 1];
    const lastMoveActive =
      lastMove.direction === direction && lastMove.x === x && lastMove.y === y;
    if (lastMoveActive) {
      setActive(true);
      setActivePlayer(lastMove.player);
    }
  }, [moves]);

  const color =
    activePlayer !== undefined
      ? { background: players[activePlayer].color }
      : undefined;

  return (
    <button
      className={`${cn()} ${cn(`-${direction}`, { '-active': active })}`.trim()}
      onClick={handleMove}
      style={color}
    ></button>
  );
};

export default Line;
