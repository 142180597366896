const defaultGame: GameType = {
  width: 4,
  height: 4,
  players: [
    { name: 'Jan', color: 'yellow' },
    { name: 'Piet', color: 'red' },
  ],
  currentPlayerId: 0,
};

export default defaultGame;
