import React from 'react';
import bemClassNames from '@enrise/bemclassnames';
import PlayingField from '~components/PlayingField';
import { useGameState } from '~state/game';
import './Home.scss';

const cn = bemClassNames('PageHome');

const Home = () => {
  const [{ players, currentPlayerId }] = useGameState();
  const currentPlayer = players[currentPlayerId];
  const playerStyle = { background: currentPlayer.color };
  return (
    <main className={cn()}>
      Current player: {currentPlayer.name}{' '}
      <span className={cn('player')} style={playerStyle}></span>
      <div className={cn('playingFieldContainer')}>
        <PlayingField />
      </div>
    </main>
  );
};

export default Home;
