import { useState, useEffect, useCallback } from 'react';
import { useMovesState } from '~state/moves';
import { useGameState } from '~state/game';

const useBoxState = (x: number, y: number) => {
  const [boxState, setBoxState] = useState<BoxStateType>({
    top: false,
    right: false,
    bottom: false,
    left: false,
  });
  const [moveCounter, setMoveCounter] = useState(0);
  const [hasLastMove, setHasLastMove] = useState(false);

  const [moves, setMoves] = useMovesState();
  const [{ players }] = useGameState();

  const boxConqured =
    boxState.top && boxState.bottom && boxState.left && boxState.right;

  const playerConquered =
    boxConqured && boxState.lastPlayer !== undefined
      ? players[boxState.lastPlayer]
      : null;

  const processMove = useCallback(
    (move: MoveType) => {
      const isTop =
        move.x === x && move.y === y && move.direction === 'horizontal';
      const isLeft =
        move.x === x && move.y === y && move.direction === 'vertical';
      const isBottom =
        move.x === x && move.y === y + 1 && move.direction === 'horizontal';
      const isRight =
        move.x === x + 1 && move.y === y && move.direction === 'vertical';

      if (isTop) {
        setBoxState((prevState) => ({
          ...prevState,
          top: true,
          lastPlayer: move.player,
        }));
      }
      if (isBottom) {
        setBoxState((prevState) => ({
          ...prevState,
          bottom: true,
          lastPlayer: move.player,
        }));
      }
      if (isLeft) {
        setBoxState((prevState) => ({
          ...prevState,
          left: true,
          lastPlayer: move.player,
        }));
      }
      if (isRight) {
        setBoxState((prevState) => ({
          ...prevState,
          right: true,
          lastPlayer: move.player,
        }));
      }
      return isTop || isBottom || isLeft || isRight;
    },
    [x, y]
  );

  useEffect(() => {
    if (!moves.length || boxConqured) {
      return;
    }
    let boxHasLastMove = false;
    for (let i = moveCounter; i < moves.length; i++) {
      boxHasLastMove = processMove(moves[i]);
    }
    setHasLastMove(boxHasLastMove);
    setMoveCounter(moves.length);
  }, [moves]);

  useEffect(() => {
    if (hasLastMove && boxConqured) {
      setMoves((prevousState) => {
        const lastMove = moves[moveCounter - 1];
        const newState = prevousState.slice(0, prevousState.length);
        newState.push({
          ...lastMove,
          playerConquered: true,
        });
        return newState;
      });
    }
  }, [hasLastMove, boxConqured]);

  return { boxConqured, playerConquered };
};

export default useBoxState;
